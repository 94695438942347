<template>
    <div>
        <div class="finder">
          <b-form-input
            class="Input"
            :id="`clinicFinderSearch2`"
            type="text"
            :placeholder="$i18n.t('cp_search')"
            v-model="clinicText"
            @input.native="handleTextChange"
          />
          <CityFinder
            id="CityFinder"
            :citys="this.city"
            @handlerChangeCityFilter="handleChangeCity"
          />
          <Specialty
         id="specialtyDoctorFilter"
         :value="getSpecialty"
         @handleChangeSpecialty="handleChangeSpecialty"
          />
          <b-button
          @click.prevent="handleSearchClinic(pagination)"
          variant="primary"
        >
          {{ $i18n.t('btn_search') }}
          </b-button>
        </div>
        <div
          v-if="!isValid"
          class="text__sre"
        >
          {{ $i18n.t('val_clinic') }}
        </div>
        <div class="sp__v" />
        <div>
          <Spinner
            :isLoading="isLoading"
            variant="primary"
          />
          <div v-if="clinicsResponse !== null && !isLoading" class="results">
            <div class="results-clinic-list">
                <ClinicCard
                  v-for="(clinicItem, index) in clinicsResponse.clinicList"
                  :key="index"
                  :clinicItem="clinicItem"
                  :isRadio="true"
                  :isLoading="isLoading"
                  @handleSelect="handleSelect"
                  :clinicSelected="clinicSelected"
                />
            </div>

            <Pagination
              v-if="showPagination"
              :pagination="this.clinicsResponse.pagination"
              @handleRequest="handlePaginate"
            />
          </div>
        </div>
    </div>
</template>
<script>
import CityFinder from '../CityFinder/CityFinder.vue';
import Specialty from '@/components/atoms/Select/Specialty.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ClinicCard from '@/components/atoms/Cards/ClinicCard/ClinicCard.vue';

export default {
  props: {
    clinicSelected: String,
    isValid: {
      type: Boolean,
      default: true,
    },
    requestId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
      specialty: null,
      city: null,
      clinicText: '',
      isLoading: false,
      clinicsResponse: null,
      showPagination: false,
    };
  },
  methods: {
    handlePaginate(currentpage) {
      this.handleSearchClinic(currentpage);
    },
    handleSelect(value) {
      this.$emit('handleClinicSelection', value);
    },
    handleChangeCity(value) {
      this.city = value;
    },
    handleChangeSpecialty(value) {
      this.specialty = value ? value.id : null;
    },
    handleTextChange(e) {
      this.clinicText = e.target.value;
    },
    handleSearchClinic(currentpage) {
      this.isLoading = true;
      this.$store.dispatch('invitations/findClinics', this.getClinicsData(currentpage))
        .then((res) => {
          this.clinicsResponse = res;
          this.showPagination = res.clinicList.length > 0;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getClinicsData(currentpage = 1) {
      return {
        page: currentpage || 1,
        perPage: 6,
        filters: this.getFilters(),
      };
    },
    getFilters() {
      const filtersGroup = [];

      filtersGroup.push(`request:${this.requestId}`);

      if (this.city !== null) {
        filtersGroup.push(
          `city:${this.city}`,
        );
      }
      if (this.specialty !== null) {
        filtersGroup.push(
          `specialties:${this.specialty}`,
        );
      }
      if (this.clinicText !== undefined && this.clinicText !== '') {
        filtersGroup.push(`name:${this.clinicText}`);
      }

      return filtersGroup;
    },
  },
  computed: {
    getSpecialty() {
      return this.specialty;
    },
  },
  components: {
    CityFinder,
    Specialty,
    Spinner,
    Pagination,
    ClinicCard,
  },
};
</script>
<style lang="sass" scoped>
  @import './ClinicFinder.sass'
</style>
