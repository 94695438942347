<template>
    <ModalAction
      :id="id"
      :okLabel="$i18n.t('btn_accept')"
      :hideHeader="true"
      @handleOk="handleOk"
      isCentered
      onlyOk
    >
    <Spinner
      :isLoading="isLoading"
      variant="primary"
    />
    <div v-if="!isLoading && invitation.clinic === undefined" class="clinic">
        <h4>{{$i18n.t('lb_collaboration_result_email_title')}}</h4>
        <span>{{$i18n.t('lb_collaboration_result_email_subtitle')}}</span>
        <p>{{invitation.email}}{{$i18n.t('txt_invitation_email')}}</p>
    </div>
    <div v-if="!isLoading && invitation.clinic !== undefined" class="clinic">
        <h4>{{$i18n.t('lb_collaboration_result_email_title')}}</h4>
        <span>{{$i18n.t('lb_collaboration_result_clinic_subtitle')}}</span>
        <div class="clinic">
          <Avatar
            :image="getAvatarIcon(invitation.clinic.avatar)"
            size="45px"
          />
          <span class="text__psb clamp">{{invitation.clinic.name}}</span>
          <span class="text__sr clamp">{{invitation.clinic.phone}}</span>
          <span class="text__sr clamp">{{invitation.clinic.email}}</span>
        </div>
    </div>
    </ModalAction>
</template>
<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';
import { getAvatarByProps } from '@/helpers/UserHelper';
import Avatar from '@/components/atoms/Avatar/Avatar.vue';

export default {
  name: 'InvitationResultModal',
  props: {
    id: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    invitation: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  methods: {
    handleOk() {
      this.$root.$emit('bv::hide::modal', this.id, '#btnShow');
    },
    getAvatarIcon(avatar) {
      return getAvatarByProps(avatar);
    },
  },
  components: {
    ModalAction,
    Spinner,
    Avatar,
  },
};
</script>
<style lang="sass" scoped>
  @import './invitation.sass'
</style>
