<template>
    <div>
        <InputGroupAutocomplete
        id="cityClinicFilter"
        :placeholder="$i18n.t('lb_type_city')"
        @handlerInputChange="handlerChangeCity"
        @handlerSelectSuggestion="handlerSelectSuggestion"
        :value="getCity"
        :suggestions="cities"
        :showList="showList"
        :isLoading="isLoading"
      />
    </div>
</template>
<script>
import InputGroupAutocomplete from '@/components/atoms/Input/InputGroupAutocomplete.vue';
import { MIN_SEARCH__LENGTH, DEBOUNCE_TIME } from '@/helpers/ComponentHelper';

export default {
  props: {
    citys: {
      type: String,
    },
  },
  data() {
    return {
      city: this.citys,
      searchText: null,
      cities: [],
      showList: false,
      isLoading: false,
      timeout: null,
    };
  },
  methods: {
    loadCities() {
      this.isLoading = true;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch('cities/getAllCities', this.searchText)
          .then((res) => {
            this.cities = res;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }, DEBOUNCE_TIME);
      this.showList = this.cities.length >= 1;
    },

    getTextLowercased(text) {
      return text.toLowerCase();
    },

    handlerChangeCity(value) {
      if (value?.length >= MIN_SEARCH__LENGTH) {
        this.searchText = value;
        this.loadCities();
      }
      if (!value) {
        this.cities = [];
        this.handlerSelectSuggestion(null);
      }
    },

    handlerSelectSuggestion(value) {
      this.$emit('handlerChangeCityFilter', value);
      this.cities = [];
    },
  },
  computed: {
    getCity() {
      return this.citys;
    },
  },
  components: {
    InputGroupAutocomplete,
  },
};
</script>
<style lang="sass" scoped></style>
