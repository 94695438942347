<template>
    <ModalAction
      :id="id"
      :title="$i18n.t('lb_collaboration_modal_title')"
      :okLabel="$i18n.t('btn_invitation')"
      :cancelLabel="$i18n.t('btn_cancel')"
      @handleOk="handleSendInvitation"
      @handleCancel="handleCancel"
      isForm
    >
    <b-form-group>
      <b-row>
      <b-col>
        <div class="radio">
          <input
            type="radio"
            id="clinic"
            name="drone"
            value="clinic"
            v-model="picked"
            @change="handleRadioSelected($event)"
          >
          <label for="clinic">{{$i18n.t('cp_radio_clinic')}}</label>
        </div>
        <div class="option" v-if="picked === 'clinic'">
          <!-- <div class="option-Cover" v-if="picked === 'email'"/> -->
          <b-row>
            <b-col>
              <ClinicFinder
                id="clinicFinder"
                @handleClinicSelection="handleClinicSelection"
                :clinicSelected="invitationNewRegister.clinicId"
                :isValid="validationState.clinicId"
                :requestId="requestId"
              />
              <TextareaGroup
              id="registeredClinicComment"
              :label="$i18n.t('lb_collaboration_modal_comment_title')"
              :placeholder="$i18n.t('lb_collaboration_modal_comment_placeholder')"
              @handlerTextareaChange="handleChangeClinicComments"
              :value="this.invitationClinic.comments"
            />
            </b-col>
          </b-row>
        </div>
        <hr/>
        <div class="radio">
          <input
            type="radio"
            id="email"
            name="drone"
            value="email"
            v-model="picked"
            @change="handleRadioSelected($event)"
          >
          <label for="email">{{$i18n.t('cp_radio_email')}}</label>
        </div>
        <div class="option" v-if="picked === 'email'">
          <!-- <div class="option-Cover" v-if="picked === 'clinic'"/> -->
          <b-row>
            <b-col>
              <InputGroup
              id="invitationFormName"
              :label="$i18n.t('lb_collaboration_modal_name_title')"
              :placeholder="$i18n.t('lb_collaboration_modal_name_placeholder')"
              @handlerInputChange="handleChangeName"
              :isValid="this.validationState.name"
              :error="$i18n.t('val_empty')"
              :value="this.invitationNewRegister.name"
            />
            </b-col>
            <b-col>
              <InputGroup
              id="invitationFormEmail"
              :label="$i18n.t('lb_collaboration_modal_email_title')"
              :placeholder="$i18n.t('lb_collaboration_modal_email_placeholder')"
              @handlerInputChange="handleChangeEmail"
              :isValid="this.validationState.email"
              :error="$i18n.t('val_empty')"
              :value="this.invitationNewRegister.email"
            />
            </b-col>
          </b-row>
          <div class="sp__v__2x" />
          <b-row>
            <b-col>
              <TextareaGroup
              id="registeredClinicComment"
              :label="$i18n.t('lb_collaboration_modal_comment_title')"
              :placeholder="$i18n.t('lb_collaboration_modal_comment_placeholder')"
              @handlerTextareaChange="handleChangeComments"
              :value="this.invitationNewRegister.comments"
            />
            </b-col>
          </b-row>
        </div>
      </b-col>
      </b-row>
    </b-form-group>
    </ModalAction>
  </template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import TextareaGroup from '@/components/atoms/Textarea/TextareaGroup.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import { getValidationState, isValidForm, sanitizeEmail } from '@/helpers/ValidationHelper';
import ClinicFinder from '@/components/molecules/ClinicFinder/ClinicFinder.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    requestId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      picked: 'clinic',
      selected: 'A',
      invitationClinic: {
        clinic: {},
        clinicId: '',
        comments: '',
      },
      invitationNewRegister: {
        name: '',
        email: '',
        comments: '',
      },
      validationState: {
        name: null,
        email: null,
        clinicId: true,
      },
    };
  },
  methods: {
    handleSendInvitation() {
      if (this.picked === 'clinic') { this.sendClinicInvitation(); }
      if (this.picked === 'email') { this.sendEmailInvitation(); }
    },
    sendClinicInvitation() {
      const clinicValidationState = getValidationState(
        this.invitationClinic.clinicId,
      );
      if (isValidForm([
        clinicValidationState,
      ])) {
        this.$emit('sendInvitation', this.invitationClinic);
        this.$root.$emit('bv::hide::modal', this.id, '#btnShow');
        this.reset();
      } else {
        this.validationState.clinicId = clinicValidationState;
      }
    },
    sendEmailInvitation() {
      this.setSanitizedEmail();
      const nameValidationState = getValidationState(
        this.invitationNewRegister.name,
      );
      const emailValidationState = getValidationState(
        this.invitationNewRegister.email,
      );

      if (isValidForm([
        nameValidationState,
        emailValidationState,
      ])) {
        this.$emit('sendInvitation', this.invitationNewRegister);
        this.$root.$emit('bv::hide::modal', this.id, '#btnShow');
        this.reset();
      } else {
        this.validationState.name = nameValidationState;
        this.validationState.email = emailValidationState;
      }
    },
    setSanitizedEmail() {
      const sanitizedEmail = sanitizeEmail(this.invitationNewRegister.email);
      this.invitationNewRegister.email = sanitizedEmail;
    },
    resetValidations() {
      this.validationState = {
        name: null,
        email: null,
        clinicId: true,
      };
    },
    handleRadioSelected(event) {
      if (event.target.value === 'clinic') { this.cleanInvitationNewRegisters(); this.resetValidations(); }
      if (event.target.value === 'email') { this.cleanInvitationClinic(); }
    },
    handleChangeName(text) {
      this.invitationNewRegister.name = text;
    },
    handleChangeComments(text) {
      this.invitationNewRegister.comments = text;
    },
    handleChangeClinicComments(text) {
      this.invitationClinic.comments = text;
    },
    handleChangeEmail(text) {
      this.invitationNewRegister.email = text;
    },
    handleClinicSelection(value) {
      this.invitationClinic.clinic = value;
      this.invitationClinic.clinicId = value.id;
      this.resetValidations();
    },
    handleCancel() {
      this.reset();
      this.$emit('handleCancel');
    },
    cleanInvitationNewRegisters() {
      this.invitationNewRegister = {
        clinicId: '',
        name: '',
        email: '',
        comments: '',
      };
    },
    cleanInvitationClinic() {
      this.invitationClinic = {
        clinic: {},
        clinicId: '',
        comments: '',
      };
    },
    reset() {
      this.resetValidations();
      this.cleanInvitationClinic();
      this.cleanInvitationNewRegisters();
      this.picked = 'clinic';
    },
  },
  computed: {
    isDisabled() {
      return !this.isEditable;
    },
  },
  components: {
    ModalAction,
    TextareaGroup,
    InputGroup,
    ClinicFinder,
  },
};
</script>
<style lang="sass" scoped>
  @import './invitation.sass'
</style>
