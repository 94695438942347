<template>
  <div :class="isCard ? 'DropdownSearch w-card' : ''" >
    <Dropdown
      :text="text"
      :icon="icon"
      :isValid="isValid"
    >
      <Input
        id="searchIwefsd"
        type="text"
        :placeholder="$i18n.t('cp_search')"
        @handlerInputChange="handleSearch"
        :value="inputValue"
      />
      <hr />
      <template
        v-for="item in items"
      >
        <b-dropdown-item-button
          v-if="hasItems"
          @click.native="handleSelectItem(item)"
          :key="item.id"
        >
          <IconGroup
            v-if="isPatient"
            :icon="getIcon(item.avatar)"
            :label="`${item.name} ${item.lastname}`"
          />

          <IconGroup
            v-else
            :icon="getIcon(item.avatar)"
            :label="item.name"
          />
          <div class="sp__v"/>
        </b-dropdown-item-button>
      </template>

      <b-dropdown-item-button
        v-if="!isLoading && hasAction"
        @click.native="handleAction"
      >
        <div class="d-flex">
          <img src="@/assets/components/plus-primary.svg" />
          <div class="sp__1s5x"/>
          <span class="text-primary">{{ actionText }}</span>
        </div>
      </b-dropdown-item-button>
      <Spinner
        :isLoading="isLoading"
        variant="primary"
      />
      <div class="sp__v"/>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from '@/components/atoms/Dropdown/Dropdown.vue';
import Input from '@/components/atoms/Input/Input.vue';
import IconGroup from '@/components/atoms/Icon/IconGroup/IconGroup.vue';
import Spinner from '@/components/atoms/Spinner/Spinner.vue';
import { getAvatarUrl } from '@/helpers/UserHelper';
import emptyAvatar from '@/assets/user/empty-avatar-dark.svg';
import { DEBOUNCE_TIME } from '../../../helpers/ComponentHelper';

const MIN_SEARCH_LENGHT = 3;

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isCard: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasAction: {
      type: Boolean,
      default: true,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    isPatient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      timeout: null,
    };
  },
  methods: {
    handleSearch(text) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (text && text.length >= MIN_SEARCH_LENGHT) {
          this.$emit('handleSearch', text);
          this.inputValue = text;
        }
      }, DEBOUNCE_TIME);
    },

    handleSelectItem(value) {
      this.$emit('handleSelectItem', value);
      this.inputValue = '';
    },

    handleAction() {
      this.$emit('handleAction');
    },

    getIcon(avatar) {
      if (avatar !== null && typeof avatar !== 'undefined') {
        return getAvatarUrl(avatar);
      }

      return emptyAvatar;
    },
  },
  computed: {
    hasItems() {
      return this.items.length > 0;
    },
  },
  components: {
    Dropdown,
    Input,
    IconGroup,
    Spinner,
  },
};
</script>

<style lang="sass" scoped>
@import './Dropdown.sass'
</style>
